<script setup lang="ts">
import { useUserStore } from "~/stores/user";

const userStore = useUserStore();
</script>

<template>
  <header class="sticky top-0 z-30 h-14 sm:h-16 flex justify-between items-center gap-4 bg-background px-4 sm:static sm:border-0 sm:bg-transparent sm:px-6">
    <span class="hidden sm:block" />
    <NuxtButton
      class="block sm:hidden" 
      variant="link" 
      color="black" 
      :padded="false" 
      icon="i-heroicons-bars-3" 
      @click="userStore.isOpenMobileSidebar = !userStore.isOpenMobileSidebar" 
    />
    <div class="flex items-center gap-4">
      <!-- <LayoutQuickSetupSidePanel 
        v-if="userStore.user?.role === 'owner'"
      /> -->
      <LayoutNotification />
      <LayoutUserAvatarDropdown />
    </div>
  </header>
</template>

<style scoped>

</style>