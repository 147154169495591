export const useMediaStore = defineStore("media", () => {
  const { $toast } = useNuxtApp();

  async function uploadFile({
    file,
    folder,
    category,
  }: {
    file: File;
    folder: string;
    category: string;
  }) {
    const formData = new FormData();
    const fileName = file.name.endsWith(".jpg") ? file.name.slice(0, -4) + ".jpeg" : file.name;
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('folder', folder);
    formData.append('category', category);
    try {
      const res = await $fetch('/api/file/upload', {
        method: 'POST',
        body: formData,
      });
      const extension = fileName.split('.').pop()?.toLowerCase() || '';
      const mimeType = file.type || 'application/octet-stream';
      const fileSize = file.size;
      return {
        ...res,
        extension,
        mime_type: mimeType,
        file_size: fileSize,
      }
    } catch (error) {
      $toast.error('Failed to upload file. Please try again later or contact support.');
      throw error;
    }
  }

  async function uploadMedia({
    file,
    agentId,
  }: {
    file: File;
    agentId: string;
  }) {
    const formData = new FormData();
    const fileName = file.name.endsWith(".jpg") ? file.name.slice(0, -4) + ".jpeg" : file.name;
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('agentId', agentId);
    try {
      const res = await $fetch('/api/media/upload', {
        method: 'POST',
        body: formData,
      });
      return {
        ...res,
        file
      };
    } catch (error) {
      $toast.error('Failed to upload media. Please try again later or contact support.');
      throw error;
    }
  }

  return {
    uploadFile,
    uploadMedia,
  };
});

export type Media = {
  name: string;
  path: string;
  file_size: number;
  mime_type: string;
  extension: string;
  width: number;
  height: number;
}