import dayjs from 'dayjs';
import { useUserStore } from '~/stores/user';
import { useMediaStore } from '~/stores/media';
import { useSound } from '@vueuse/sound'
import bellSound from '~/assets/sound/bell.mp3';
import Logo from '~/assets/img/favicon.png';

export const useInboxStore = defineStore('inbox', () => {
  const route = useRoute();
  const { $supabaseClient, $toast } = useNuxtApp();

  const userStore = useUserStore();
  const mediaStore = useMediaStore();

  const isDisableWorkflow = ref(false);
  const isOpenConversationList = ref(false);
  const isInMobile = ref(false);
  const sendingMessage = ref(false);
  const messageInput = ref('');
  const isCustomerServiceConversationWindowExpired = ref(false);
  const enableConversationAssignmentNotification = ref(true);

  const { play } = useSound(bellSound);

  const isInInboxPage = computed(() => {
    return route.path.startsWith('/inbox');
  })

  const selectedConversation = computed(() => {
    if (!selectedConversationId.value) return null;
    const conversation = allConversations.value.find((item) => item.id === selectedConversationId.value);
    const messagesGroupByDate = (conversation?.messages ?? [])
      .filter((message) => message.type !== 'reaction')
      .reduce((acc, message) => {
        const date = dayjs(message.created_at).format('DD/MMM/YYYY');
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {} as Record<string, Message[]>);
    return {
      ...conversation,
      messagesGroupByDate,
    };
  })

  const hasRunningWorkflow = computed(() => {
    return ['running', 'failed'].includes(selectedConversation.value?.agent_executions?.[0]?.status);
  })

  const isDisableMessageTextarea = computed(() => {
    return selectedConversation.value?.status === 'closed'
      || hasRunningWorkflow.value
      || sendingMessage.value
      || isCustomerServiceConversationWindowExpired.value
      || !isConversationOwner.value;
  })

  /**
   * Contact
   */
  const contacts = ref<Contact[]>([]);

  async function fetchWorkspaceContacts() {
    try {
      const { data, error } = await $supabaseClient
        .from('workspace_contact')
        .select(`
          id,
          first_name,
          last_name,
          email,
          country,
          note,
          disable_workflow,
          phone_number:contact_id,
          ...whatsapp_conversations (
            whatsapp_conversation_id:id
          ),
          workspace_contact_custom_field_value (
            id,
            custom_field_id,
            value
          ),
          tags (
            id
          )
        `)
        .eq('workspace_id', userStore.user?.workspaceId)
      if (error) throw error;
      contacts.value = data.map((item) => ({
        ...item,
        tags: (item?.tags ?? []).map((tag) => tag.id),
      }));
    } catch (error) {
      throw error;
    }
  }

  const contactIdValuePair = computed(() => {
    return contacts.value.reduce((acc, contact) => {
      acc[contact.id] = {
        ...contact,
        full_name: `${contact?.first_name ?? ''} ${contact?.last_name ?? ''}`,
      };
      return acc;
    }, {} as Record<string, Contact>);
  })

  /**
   * Phone number
   */
  const connectedPhoneNumber = ref<PhoneNumber>({
    id: '',
    display_phone_number: '',
  });

  async function getConnectedPhoneNumber() {
    try {
      const { data, error } = await $supabaseClient
        .from('whatsapp_phone_numbers')
        .select(`
          id,
          display_phone_number,
          whatsapp_business_accounts!inner()
        `)
        .eq('whatsapp_business_accounts.workspace_id', userStore.user?.workspaceId)
        .maybeSingle();
      if (error) throw error;
      if (!data) return;
      connectedPhoneNumber.value = data;
    } catch (error) {
      throw error;
    }
  }

  const hasConnectedPhoneNumber = computed(() => {
    return !!connectedPhoneNumber.value;
  })

  /**
   * Attachment
   */
  const uploadedFiles = ref<File[]>([]);

  function isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  function getFileIcon(file: File): string {
    if (file.type.startsWith("video/")) return 'i-lucide-video';
    if (file.type.startsWith("audio/")) return 'i-lucide-headphones';
    if (file.name.endsWith('.doc') || file.name.endsWith('.docx')) return 'i-lucide-file-text';
    if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) return 'i-lucide-file-spreadsheet';
    return 'i-lucide-file-text';
  }

  const attachmentThumbnails = computed(() => 
    uploadedFiles.value.map(file => ({
      name: file.name,
      url: isImage(file) ? URL.createObjectURL(file) : getFileIcon(file),
    }))
  );

  function deleteFile(index: number) {
    uploadedFiles.value.splice(index, 1);
  }

  /**
   * Conversation
   */
  const conversations = ref<Conversation[]>([]);
  const selectedConversationId = ref('');

  async function fetchConversations() {
    try {
      let query = $supabaseClient
        .from('whatsapp_conversations')
        .select(`
          id,
          status,
          closed_at,
          updated_at,
          assigned_to,
          workspace_contact_id,
          service_window_expires_at,
          marketing_window_expires_at,
          utility_window_expires_at,
          last_message:whatsapp_messages!whatsapp_conversations_last_message_id_fkey (
            type,
            content,
            status,
            direction,
            emoji,
            created_at
          ),
          user_unread_messages (
            unread_message_count,
            last_read_message_id
          ),
          agent_executions (
            status
          )
        `)
        .eq('workspace_id', userStore.user?.workspaceId)
        .eq('user_unread_messages.user_workspace_id', userStore.currentWorkspace?.user_workspace_id)
        .limit(1, {
          referencedTable: 'user_unread_messages',
        })
        .limit(isInInboxPage.value ? 1 : 0, {
          referencedTable: 'last_message',
        })
        .limit(1, {
          referencedTable: 'agent_executions',
        })
        .order('created_at', {
          ascending: false,
          referencedTable: 'agent_executions',
        })
        .order('updated_at', {
          ascending: false,
        })
      if (userStore.user?.role === 'agent') {
        query = query.eq('assigned_to', userStore.user?.uid as string)
      }
      const { data, error } = await query;
      if (error) throw error;
      conversations.value = data.map((item) => ({
        ...item,
        isMessagesLoaded: false,
      }));
      if (isInInboxPage.value && data.length > 0) {
        selectedConversationId.value = route.params?.id ?? data[0]?.id;
      }
    } catch (error) {
      throw error;
    }
  }

  async function clearUnreadMessageCount() {
    // console.log('[Clear Unread Message Count]', selectedConversation.value?.user_unread_messages?.[0]?.unread_message_count);
    if (!userStore.currentWorkspace?.user_workspace_id || !selectedConversationId.value || selectedConversation.value?.user_unread_messages?.[0]?.unread_message_count === 0) return;
    const { error } = await $supabaseClient
      .rpc('clear_unread_message_count', {
        p_user_workspace_id: userStore.currentWorkspace?.user_workspace_id,
        p_conversation_id: selectedConversationId.value,
      })
    if (error) throw error;
  }

  watch(() => selectedConversation.value?.user_unread_messages?.[0]?.unread_message_count, () => {
    clearUnreadMessageCount();
  })

  watch(selectedConversationId, async (newValue) => {
    messageInput.value = '';
    uploadedFiles.value = [];
    sendingMessage.value = false;
    clearUnreadMessageCount();
    if (newValue) await getConversationMessages(newValue);
  }, { immediate: true })

  const isMaskContactInfo = computed(() => {
    return (userStore.currentWorkspace?.is_mask_contact_info ?? true)
      && userStore.currentWorkspace?.role !== 'owner'
      && userStore.currentWorkspace?.role !== 'manager';
  })

  const allConversations = computed(() => {
    return conversations.value.map((conversation) => {
      const agent = conversation?.assigned_to ? userStore.userIdValuePairs?.[conversation?.assigned_to] : null;
      return {
        ...conversation,
        agent,
        last_message: {
          ...conversation.last_message,
          display_datetime: formatDateTime(conversation.last_message?.created_at),
        },
        contact: contactIdValuePair.value?.[conversation.workspace_contact_id],
      }
    }).sort((a, b) => {
      return dayjs(b.updated_at).diff(dayjs(a.updated_at));
    })
  })

  const isConversationOwner = computed(() => {
    return selectedConversation.value?.assigned_to === userStore.user.uid;
  })

  const selectedContactIndex = computed(() => {
    return contacts.value.findIndex((contact) => contact.id === selectedConversation.value?.contact?.id);
  })

  const replyReferenceMessageMap = computed(() => {
    const replyReferenceMessageIds = (selectedConversation.value?.messages ?? [])
      .filter((message) => message.reply_parent_message_id)
      .map((message) => message.reply_parent_message_id);
    return (selectedConversation.value?.messages ?? [])
      .filter((message) => replyReferenceMessageIds.includes(message.id))
      .reduce((acc, message) => {
        acc[message.id] = {
          id: message.id,
          content: message.content,
          sent_by: message?.agent_id,
          direction: message.direction,
          type: message.type,
        };
        return acc;
      }, {} as Record<string, Record<string, string>>);
  })

  const messageAttachmentMap = computed(() => {
    return (selectedConversation.value?.messages ?? [])
      .filter((message) => (message.attachment ?? []).length > 0)
      .reduce((acc, message) => {
        acc[message.id] = message.attachment[0];
        return acc;
      }, {} as Record<string, Attachment>);
  })

  const isLoadingConversationMessages = ref(false);

  async function getConversationMessages(conversationId: string) {
    const index = conversations.value.findIndex((item) => item.id === conversationId);
    if (index === -1) return;
    if (conversations.value[index]?.isMessagesLoaded) return;
    isLoadingConversationMessages.value = true;
    try {
      const { data, error } = await $supabaseClient
        .from("whatsapp_messages")
        .select(`
          id,
          type,
          content,
          status,
          direction,
          source,
          components,
          agent_id,
          error,
          created_at,
          sent_at,
          delivered_at,
          read_at,
          reaction:whatsapp_messages!reaction_parent_message_id (
            emoji        
          ),
          reply_parent_message_id,
          attachment:whatsapp_message_attachments (
            id,
            name,
            url,
            caption,
            file_size,
            mime_type,
            extension
          )  
        `)
        .eq("whatsapp_conversation_id", conversationId)
        .order('created_at', {
          ascending: true,
        })
      if (error) throw error;
      const index = conversations.value.findIndex((item) => item.id === conversationId);
      if (index === -1) return;
      conversations.value[index].isMessagesLoaded = true;
      conversations.value[index].messages = data;
      conversations.value[index].last_message = data[data.length - 1]
    } catch (error) {
      throw error;
    } finally {
      isLoadingConversationMessages.value = false;
    }
  }

  async function getLatestConversationMessages(conversationId: string) {
    const index = conversations.value.findIndex((item) => item.id === conversationId);
    if (index === -1) return;
    const { data, error } = await $supabaseClient
      .from("whatsapp_messages")
      .select(`
        id,
        type,
        content,
        status,
        direction,
        source,
        components,
        agent_id,
        created_at,
        sent_at,
        delivered_at,
        read_at,
        workflow_node_execution_id,
        reaction:whatsapp_messages!reaction_parent_message_id (
          emoji        
        ),
        attachment:whatsapp_message_attachments (
          id,
          name,
          url,
          caption,
          file_size,
          mime_type,
          extension
        )  
      `)
      .eq("whatsapp_conversation_id", conversationId)
      .gt('updated_at', conversations.value[index].last_message.created_at)
      .limit(10);
    if (error) throw error;
    if (data.length === 0) return;
    data.forEach((message) => {
      const messageIndex = conversations.value[index].messages.findIndex((item) => item.id === message.id);
      if (messageIndex === -1) {
        conversations.value[index].messages.push(message);
      } else {
        conversations.value[index].messages[messageIndex] = message;
      }
    })
    conversations.value[index].last_message = data[data.length - 1]
  }

  async function toggleConversationStatus(isSentMessageTemplateSuccess = false) {
    if (!isConversationOwner.value) return;
    try {
      const newStatus = selectedConversation.value?.status === 'closed' ? 'open' : 'closed';
      if (newStatus === "open" && !isSentMessageTemplateSuccess && isCustomerServiceConversationWindowExpired.value) return;
      const { error } = await $supabaseClient
        .from('whatsapp_conversations')
        .update({
          status: newStatus,
          updated_at: new Date(),
          ...(newStatus === 'closed' ? {
            closed_at: new Date()
          } : {
            opened_at: new Date(),
            opened_by: userStore.user.uid,
          }),
        })
        .eq('id', selectedConversationId.value);
      if (error) throw error;
    } catch (error) {
      $toast.error(`Failed to toggle conversation status. Please try again later or contact support.`);
      throw error;
    }
  }

  async function disableContactWorkflow() {
    const action = isDisableWorkflow.value ? 'disable' : 'enable';
    try {
      const workspaceContactId = selectedConversation.value?.workspace_contact_id;
      if (!workspaceContactId) return;
      const { error } = await $supabaseClient
        .from("workspace_contact")
        .update({
          disable_workflow: isDisableWorkflow.value,
        })
        .eq("id", workspaceContactId);
      if (error) throw error;
      if (isDisableWorkflow.value) {
        const { error: workflowExecutionError } = await $supabaseClient
          .from("agent_executions")
          .update({
            status: "terminated"
          })
          .in("status", ["running", "failed"])
          .eq("whatsapp_conversation_id", selectedConversationId.value);
        if (workflowExecutionError) throw workflowExecutionError;
      }
      $toast.success(`Contact ${action}d from AI Agent successfully`);
    } catch (error) {
      $toast.error(`Failed to ${action} contact from AI Agent. Please try again later or contact support.`);
      throw error;
    }
  }

  watch(isDisableWorkflow, (newValue) => {
    if (newValue !== selectedConversation.value?.contact?.disable_workflow) {
      disableContactWorkflow();
    }
  })

  async function deleteMessage(messageId: string) {
    try {
      const { error } = await $supabaseClient
        .from("whatsapp_messages")
        .delete()
        .eq("id", messageId);
      if (error) throw error;
      const index = conversations.value.findIndex((item) => item.id === selectedConversationId.value);
      if (index === -1) return;
      conversations.value[index].messages = conversations.value[index].messages.filter((item) => item.id !== messageId);
      conversations.value[index].last_message = conversations.value[index].messages[conversations.value[index].messages.length - 1];
      $toast.success('Message deleted successfully!');
    } catch (error) {
      throw error;
    }
  }

  async function clearConversationMessages() {
    try {
      const { error } = await $supabaseClient
        .from("whatsapp_messages")
        .delete()
        .eq("whatsapp_conversation_id", selectedConversationId.value);
      if (error) throw error;
      const { error: closeConversationError } = await $supabaseClient
        .from("whatsapp_conversations")
        .update({
          status: "closed",
          closed_at: new Date().toISOString(),
        })
        .eq("id", selectedConversationId.value);
      if (closeConversationError) throw closeConversationError;
      const index = conversations.value.findIndex((item) => item.id === selectedConversationId.value);
      if (index === -1) return;
      conversations.value[index].messages = [];
      conversations.value[index].last_message = null;
      const { error: deleteAgentExecutionError } = await $supabaseClient
        .from("agent_executions")
        .delete()
        .eq("whatsapp_conversation_id", selectedConversationId.value);
      if (deleteAgentExecutionError) throw deleteAgentExecutionError;
      $toast.success('Conversation messages cleared successfully!');
    } catch (error) {
      throw error;
    }
  }

  /**
   * Send message
   */
  async function sendWhatsAppMessage(params: any) {
    try {      
      const { error } = await $supabaseClient.functions.invoke('send-message', {
        body: {
          conversationId: selectedConversationId.value,
          ...params,
        }
      })
      if (error) throw error;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendMediaMessage(file: File) {
    const attachment = await mediaStore.uploadFile({
      file,
      folder: connectedPhoneNumber.value?.id,
      category: "whatsapp-media",
    });
    await sendWhatsAppMessage({
      attachment,
    })
  }

  async function sendMessage({
    content,
    messageTemplateId,
    variableValues,
    messageToSend,
  }: {
    content?: string;
    messageTemplateId?: string;
    variableValues?: string[];
    messageToSend?: string;
  }) {
    const index = conversations.value.findIndex((item) => item.id === selectedConversationId.value);
    if (index === -1) return;
    if (selectedConversation.value?.status === 'closed' && !messageTemplateId) {
      $toast.error('Conversation is closed. You cannot send message to this contact until the contact reopened the conversation');  
      return;
    }
    sendingMessage.value = true;
    try {
      if (messageTemplateId) {
        await sendWhatsAppMessage({
          variableValues,
          messageTemplateId,
          messageTemplateBody: messageToSend,
        })
        $toast.success('Message template sent successfully!');
        if (selectedConversation.value?.status === 'closed') {
          await toggleConversationStatus();
        }
        return;
      }
      if (uploadedFiles.value.length > 0 && !messageTemplateId) {
        const promises = uploadedFiles.value.map((file) => sendMediaMessage(file));
        if (content) promises.unshift(sendWhatsAppMessage({ content }))
        await Promise.all(promises)
      } else if (content) {
        await sendWhatsAppMessage({
          content,
        })
      }
      uploadedFiles.value = [];
      $toast.success('Message sent successfully!');
    } catch (error) {
      $toast.error('Failed to send message. Please try again later or contact support.');
      throw error;
    } finally {
      sendingMessage.value = false;
    }
  }

  async function notifyUser({
    type,
    contactName,
  }: {
    type: string;
    contactName: string;
  }) {
    if (!enableConversationAssignmentNotification.value) return;
    play();

    let permission = Notification.permission;

    if (!("Notification" in window) || permission === "denied") return;

    if (permission === "default") {
      permission = await Notification.requestPermission();
    }

    let message = 'New notification';

    if (type === "conversation_assigned") {
      message = `Contact ${contactName} has been assigned to you`
    } else if (type === "conversation_unassigned") {
      message = `Contact ${contactName} has been unassigned from you`
    }

    if (permission === "granted") {
      new Notification(contactName, {
        body: message,
        icon: Logo,
      });
    }
  }

  /**
   * Realtime
   */
  const conversationChannel = ref<any>();
  const messageConversationKeyPair = ref({});

  function subscribeToConversationChannel() {
    const channel = $supabaseClient
      .channel("whatsapp-conversation")
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'workspace_contact',
          filter: `workspace_id=eq.${userStore.user?.workspaceId}`,
        },
        (payload: any) => {
          // console.log('Workspace Contact: ', payload)
          if (payload.eventType === 'INSERT') {
            contacts.value.unshift({
              ...payload?.new,
              phone_number: payload.new?.contact_id,
            });
          }
          else if (payload.eventType === 'UPDATE') {
            const index = contacts.value.findIndex((item) => item.id === payload.new.id);
            if (index === -1) return;
            contacts.value[index] = {
              ...contacts.value[index],
              ...payload.new,
            }
          }
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'whatsapp_conversations',
          filter: `workspace_id=eq.${userStore.user?.workspaceId}`,
        },
        (payload: any) => {
          // console.log('WhatsApp Conversation: ', payload)
          const index = conversations.value.findIndex((item) => item.id === payload.new.id);
          const canViewConversation = userStore.user?.role === "agent" ? payload?.new?.assigned_to === userStore.user?.uid : true;
          if (!canViewConversation) {
            if (index !== -1) {
              conversations.value.splice(index, 1);
              selectedConversationId.value = allConversations.value?.[0]?.id;
            }
            return;
          }
          if (payload.eventType === 'INSERT' && canViewConversation) {
            conversations.value.unshift(payload?.new);
          }
          else if (payload.eventType === 'UPDATE') {
            if (index === -1) {
              conversations.value.unshift({
                ...payload.new,
                messages: [],
              })
              getConversationMessages(payload.new.id);
            } else {
              conversations.value[index] = {
                ...conversations.value[index],
                ...payload.new,
              }
            }
          }
          if (!selectedConversationId.value && isInInboxPage.value) selectedConversationId.value = payload.new?.id;
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'whatsapp_messages',
        },
        (payload: any) => {
          // console.log('WhatsApp Message: ', payload)
          const conversationIndex = conversations.value.findIndex((item) => item.id === payload.new.whatsapp_conversation_id);
          if (conversationIndex === -1) return;
          messageConversationKeyPair.value[payload.new.id] = payload.new.whatsapp_conversation_id;
          if ((conversations.value?.[conversationIndex]?.messages ?? []).length === 0) {
            conversations.value[conversationIndex].messages = [];
          }
          if (payload.eventType === "INSERT") {
            if (payload.new.type === 'reaction') {
              const parentMessageIndex = conversations.value[conversationIndex].messages.findIndex((item) => item.id === payload.new.reaction_parent_message_id);
              if (parentMessageIndex === -1) return;
              conversations.value[conversationIndex].messages[parentMessageIndex].reaction.push({ emoji: payload.new.emoji });
            } else {
              conversations.value[conversationIndex].messages.push(payload.new);
            }
            conversations.value[conversationIndex].last_message = payload.new;
          }
          else if (payload.eventType === "UPDATE") {
            const messageIndex = conversations.value[conversationIndex].messages.findIndex((item) => item.id === payload.new.id);
            if (messageIndex === -1) return;
            conversations.value[conversationIndex].messages[messageIndex] = {
              ...conversations.value[conversationIndex].messages[messageIndex],
              ...payload.new,
            }
          }
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'whatsapp_message_attachments',
        },
        (payload: any) => {
          // console.log('WhatsApp Message Attachment: ', payload)
          const conversationId = messageConversationKeyPair.value[payload.new.whatsapp_message_id];
          if (!conversationId) return;
          const conversationIndex = conversations.value.findIndex((item) => item.id === conversationId);
          if (conversationIndex === -1) return;
          const messageIndex = conversations.value[conversationIndex].messages.findIndex((item) => item.id === payload.new.whatsapp_message_id);
          if (messageIndex === -1) return;
          conversations.value[conversationIndex].messages[messageIndex].attachment = [payload.new];
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'agent_executions',
        },
        (payload: any) => {
          // console.log('Agent Execution: ', payload)
          const index = conversations.value.findIndex((item) => item.id === payload.new.whatsapp_conversation_id);
          if (index === -1) return;
          if (!conversations.value[index]?.agent_executions) conversations.value[index].agent_executions = [{ status: payload.new.status }];
          conversations.value[index].agent_executions[0].status = payload.new.status;
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'user_unread_messages',
          filter: `user_workspace_id=eq.${userStore.currentWorkspace?.user_workspace_id}`,
        },
        (payload: any) => {
          // console.log('[User Unread Messages Channel]', payload);
          const index = conversations.value.findIndex((item) => item.id === payload.new.whatsapp_conversation_id);
          if (index === -1) return;
          conversations.value[index].user_unread_messages = [payload.new];
        }
      )
      .subscribe((status, err) => {
        if (err) throw err;
        // console.log(`[Conversation channel: ${status}]`)
      })
    conversationChannel.value = channel;
  }

  return {
    //* Contact
    contacts,
    contactIdValuePair,
    fetchWorkspaceContacts,
    //* Phone number
    connectedPhoneNumber,
    hasConnectedPhoneNumber,
    getConnectedPhoneNumber,
    //* Conversation
    conversations,
    isOpenConversationList,
    isMaskContactInfo,
    allConversations,
    selectedConversationId,
    selectedConversation,
    selectedContactIndex,
    isConversationOwner,
    replyReferenceMessageMap,
    messageAttachmentMap,
    isLoadingConversationMessages,
    fetchConversations,
    getConversationMessages,
    getLatestConversationMessages,
    toggleConversationStatus,
    deleteMessage,
    clearConversationMessages,
    //* Realtime
    conversationChannel,
    subscribeToConversationChannel,
    //* Others
    isInMobile,
    isInInboxPage,
    isDisableWorkflow,
    sendingMessage,
    hasRunningWorkflow,
    isCustomerServiceConversationWindowExpired,
    isDisableMessageTextarea,
    uploadedFiles,
    attachmentThumbnails,
    messageInput,
    enableConversationAssignmentNotification,
    sendMessage,
    notifyUser,
    deleteFile,
  };
});

type MessageStatus = 'sent' | 'delivered' | 'read' | 'failed' | 'deleted' | 'received';

type MessageDirection = 'incoming' | 'outgoing';

export type ConversationCategory = 'service' | 'marketing' | 'utility';

export interface Attachment {
  id: string;
  name: string;
  url: string;
  extension: string;
  caption: string;
  file_size: number;
  mime_type: string;
}

export interface Message {
  id: string;
  type: string;
  content: string;
  status: MessageStatus;
  direction: MessageDirection;
  reply_parent_message_id?: string;
  reaction: {
    emoji: string;
  }[];
  attachment: Attachment[];
  agent_id?: string;
  created_at: string;
  sent_at: string;
  delivered_at: string;
  read_at: string;
}

export interface ContactCustomFieldValue {
  id: string;
  custom_field_id: string;
  value: string;
}

export interface Contact {
  id: string;
  first_name: string;
  last_name?: string;
  full_name?: string;
  email?: string;
  country?: string;
  note?: string;
  disable_workflow: boolean;
  phone_number: string;
  workflow_execution_status?: string;
  workspace_contact_custom_field_value: ContactCustomFieldValue[];
  tags: string[];
}

export interface Conversation {
  id: string;
  status: string;
  closed_at: string;
  updated_at: string;
  assigned_to?: string;
  workspace_contact_id: string;
  last_message: Message;
  messages: Message[];
  service_window_expires_at: string;
  marketing_window_expires_at: string;
  utility_window_expires_at: string;
  user_unread_messages?: {
    unread_message_count: number;
    last_read_message_id: string;
  }[];
  agent_executions?: {
    status: string;
  }[];
  isMessagesLoaded: boolean;
}

export interface PhoneNumber {
  id: string;
  display_phone_number: string;
}

export const conversationWindowExpiredReminderMessage = "Customer service conversation window has closed. You can only re-initiate this conversation by sending an approved message template.";