<script setup lang="ts">
import { z } from 'zod'
import type { FormSubmitEvent } from '#ui/types';
import { usePosthogStore } from '~/stores/posthog';

definePageMeta({
  title: "Sign In",
  layout: false,
});

const route = useRoute();
const { $toast } = useNuxtApp();
const supabase = useSupabaseClient()
const posthogStore = usePosthogStore();

const schema = z.object({
  email: z.string().email('Invalid email').min(1, 'Email is required').max(100, 'Email cannot be more than 100 characters'),
  password: z.string().min(8, 'Must be at least 8 characters')
})

type Schema = z.output<typeof schema>

const state = reactive({
  email: '',
  password: '',
});
const errorMessage = ref('');
const submitting = ref(false);

onBeforeMount(() => {
  if (route.query?.email) {
    state.email = route.query.email as string;
  }
})

async function handleSignIn (event: FormSubmitEvent<Schema>) {
  submitting.value = true;
  const { error } = await supabase.auth.signInWithPassword({
    email: event.data.email,
    password: event.data.password,
  })
  if (error) {
    errorMessage.value = error.message;
    submitting.value = false;
    return;
  }
  posthogStore.identifyUser();
  posthogStore.track('User logged in');
  submitting.value = false;
  window.location.href = '/auth/confirm';
}

function fillInRandomUser() {
  state.email = "test@test.local";
  state.password = "Hyper@123";
}
</script>

<template>
  <div>
    <NuxtLayout name="auth">
      <template #title> 
        {{ $t('auth.login.title') }}
      </template>
      <template #description> 
        {{ $t('auth.login.description') }}
      </template>
      <template v-if="errorMessage" #error-message>
        {{ errorMessage }}
      </template>
      <NuxtForm 
        :schema="schema"
        :state="state"
        class="space-y-4" 
        @submit="handleSignIn"
      >
        <NuxtFormGroup 
          :label="$t('auth.signup.form.email')" 
          name="email"
          required
          size="lg"
        >
          <NuxtInput 
            v-model="state.email" 
          />
        </NuxtFormGroup>
        <NuxtFormGroup 
          :label="$t('auth.signup.form.password')" 
          name="password"
          required
          size="lg"
        >
          <NuxtInput 
            class="ph-no-capture" 
            type="password" 
            v-model="state.password" 
          />
          <template #hint>
            <div class="flex items-center justify-between">
              <NuxtLink 
                to="/auth/forgot-password" 
                class="text-sm font-medium text-primary hover:underline"
              >
                {{ $t('auth.login.forgotPassword') }}
              </NuxtLink>
            </div>
          </template>
        </NuxtFormGroup>
        <NuxtButton 
          type="submit" 
          block
          size="md"
          :loading="submitting"
        >
          {{ submitting ? $t('auth.login.form.loggingIn') : $t('auth.login.form.logIn') }}
        </NuxtButton>
        <AuthGoogleOAuth />
      </NuxtForm>
      <template #footer>
        {{ $t('auth.login.signupRedirect') }}
        <NuxtLink 
          to="/auth/signup" 
          class="font-medium text-primary hover:underline"
        >
          {{ $t('auth.login.signup') }}
        </NuxtLink>
        
      </template>
    </NuxtLayout>
  </div>
</template>

<style scoped></style>
