<script setup lang="ts">
import GoogleLogo from '~/assets/img/google.svg';

withDefaults(
	defineProps<{
		isSignUp?: boolean;
	}>(),
	{
		isSignUp: false,
	}
);

const { $toast } = useNuxtApp();
const supabase = useSupabaseClient();
const isHandlingGoogleSSO = ref(false);
const url = useRequestURL();

async function triggerSignInFlow() {
	isHandlingGoogleSSO.value = true;
	const { error } = await supabase.auth.signInWithOAuth({
		provider: 'google',
		options: {
			redirectTo: `${url.origin}/auth/confirm`,
		},
	});
	if (error) {
		isHandlingGoogleSSO.value = false;
		$toast.error('Something went wrong. Please try again later or contact support.');
		throw error;
	}
}
</script>

<template>
	<NuxtDivider label="or" />
	<NuxtButton
		color="white"
		variant="solid"
		block
		size="lg"
		trailing
		:loading="isHandlingGoogleSSO"
		:ui="{
			icon: {
				size: {
					lg: 'w-4 h-4',
				},
			},
		}"
		@click="triggerSignInFlow"
	>
		<img class="w-4 h-4" alt="google" :src="GoogleLogo" />
		<template v-if="isSignUp">
			{{ isHandlingGoogleSSO ? 'Signing up with Google' : 'Sign up with Google' }}
		</template>
		<template v-else>
			Sign in with Google
		</template>
	</NuxtButton>
</template>

<style scoped></style>
