<script setup lang="ts">
import { useUserStore } from "~/stores/user";

const user = useSupabaseUser();
const userStore = useUserStore();

const actions = computed(() => {
  const workspaces = userStore.workspaces.map((workspace: any) => {
    const isOwner = userStore.user?.role === 'owner';
    const isCurrentWorkspace = userStore.user?.workspaceId === workspace.id;
    return {
      label: workspace.name,
      icon: isOwner && isCurrentWorkspace ? 'i-lucide-settings' : 'i-lucide-building-2',
      role: workspace.role,
      selected: isCurrentWorkspace,
      click: async () => {
        if (isOwner && isCurrentWorkspace) {
          return navigateTo('/settings/workspace');
        } else {
          userStore.switchWorkspace(workspace);
        }
      }
    }
  })
  return [
    [
      {
        slot: 'account',
        disabled: true,
      }
    ],
    [
      ...workspaces,
    ],
    [
      {
        label: 'Profile',
        to: '/settings/profile',
        external: false,
        icon: 'i-lucide-circle-user-round',
      },
    ],
    ...(userStore.user?.role !== 'agent' ? [[
      {
        label: 'Users',
        to: '/settings/users',
        external: false,
        icon: 'i-lucide-users',
      },
    ],
    [
      {
        label: 'Billing',
        external: true,
        icon: 'i-lucide-credit-card',
        click: () => {
          window.location.href = '/settings/billing';
        }
      }
    ]] : []),
    [
      {
        label: 'Sign Out',
        icon: 'i-lucide-log-out',
        click: async () => {
          await userStore.signOut();
        }
      }
    ]
  ];
})
</script>

<template>
  <NuxtDropdown
    :items="actions"
    :ui="{
      wrapper: 'p-0.5 rounded-full',
      width: 'w-52',
    }"
  >
    <NuxtAvatar
      :src="user?.user_metadata?.avatar_url" 
      :alt="user?.user_metadata?.full_name || 'user'"
    />
    <template #account>
      <div class="text-left">
        <p class="w-40 truncate font-medium text-gray-900">
          {{ userStore.userIdValuePairs?.[user?.app_metadata?.user_id]?.name ?? user?.user_metadata?.full_name }}
        </p>
        <p class="w-40 truncate font-normal text-gray-900">
          {{ user?.user_metadata?.email }}
        </p>
      </div>
    </template>
    <template #item="{ item }">
      <span 
        class="truncate"
        :class="item?.selected ? 'text-primary-500' : ''" 
      >
        {{ item.label }}
      </span>
      <NuxtIcon 
        :name="item.icon" 
        class="flex-shrink-0 h-4 w-4 text-gray-400 ms-auto" 
      />
    </template>
  </NuxtDropdown>
</template>

<style scoped>

</style>