export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.client) return;
  const user = useSupabaseUser();

  // console.log('Auth middleware user:', user.value?.id, {
  //   to: to.path,
  //   from: from.path,
  // });

  const publicPaths = [
    '/auth/login',
    '/auth/signup',
    '/auth/confirm',
    '/auth/invitation',
    '/auth/forgot-password',
    '/auth/update-password',
  ]

  const notPublicPath = !publicPaths.includes(to.path);

  const logWithIdentifier = (message: string) => {
    console.log(`[Middleware] (${user.value?.app_metadata?.user_id}) ${message}`);
  }

  if (!user.value && notPublicPath) {
    console.log('[Middleware] Auth middleware redirecting to login');
    return navigateTo('/auth/login'); 
  }

  if (!user.value?.app_metadata?.workspace_id && notPublicPath) {
    logWithIdentifier('No workspace id. Redirecting to onboarding');
    return navigateTo('/auth/confirm');
  }

  if (user.value?.app_metadata?.is_subscribing_free_plan !== false && notPublicPath && !['/subscriptions/plans', '/subscriptions/payment'].includes(to.path)) {
    logWithIdentifier('Not subscribing paid plan. Redirecting to subscription page');
    return navigateTo('/subscriptions/plans');
  }

  if (user.value?.app_metadata?.workspace_id && !notPublicPath && to.path !== '/auth/invitation') {
    logWithIdentifier('User authenticated. Redirecting to index page');
    return navigateTo('/inbox');
  }

  if (user.value?.app_metadata?.role === "agent" && !["/inbox", "/settings/profile"].includes(to.path)) {
    logWithIdentifier('Unauthorized access by agent user detected. Redirecting to inbox');
    return navigateTo("/inbox")
  }
})
